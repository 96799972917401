.features-split {

	.section-inner {
		padding-top: $features-split--padding-t__mobile;
		padding-bottom: $features-split--padding-b__mobile;
	}

	.section-header {
		padding-bottom: $features-split-header--padding__mobile;
	}
}

@include media( '>medium' ) {

	.side-f1 {
		flex: 1;
	}
	.side-f0 {
		flex: 0;
	}

	.features-split {

		.section-inner {
			padding-top: $features-split--padding-t__desktop;
			padding-bottom: $features-split--padding-b__desktop;
		}

		.section-header {
			padding-bottom: $features-split-header--padding__desktop;
		}
	}
}

@include media( '<medium' ) {

	.number {
		flex: 0.3;
	}
}

