.underline-gradient {
	// content: "";
	&::after {
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        bottom: 0;
        left: 0;
		margin: 10px 0 0 0;
        -webkit-transform: scaleX(0);
        -webkit-transition: transform 0.3s ease;
        background: linear-gradient(119.83deg, #F3BA88 22.41%, #B95BD7 42.19%, #E360D4 56.73%, #384AE8 74.14%);
    };
	&:hover {
		&::after {
			-webkit-transform: scaleX(1);
		}
	}
}

.split-wrap {
	margin-top: -($split-item--padding-v__mobile * 0.5);

	&:last-of-type {
		margin-bottom: -($split-item--padding-v__mobile * 0.5);
	}

    &:not(:last-of-type) {
        margin-bottom: $split-item--padding-v__mobile * 0.5;
    }

	.split-item {
		padding-top: $split-item--padding-v__mobile * 0.5;
		padding-bottom: $split-item--padding-v__mobile * 0.5;
		display: flex;
		flex-direction: column;

		.split-item-image {
			position: relative;
			margin: 0 20px 0 0;

			img,
			svg,
			video {
				width: auto;
				height: auto;
				max-width: 100%;
				overflow: visible;
			}

			&.split-item-image-fill {

				img,
				svg,
				video {
					width: 100%;
				}
			}
		}
	}
}

@include media( '<=medium' ) {

	.split-wrap {

		.split-item {

			.split-item-content {
				margin-bottom: $split-item--inner-padding-v_mobile;
			}
		}

		&.invert-mobile {

			.split-item {

				.split-item-content {
					order: 1;
					margin-bottom: 0 !important;
				}

				.split-item-image {
					margin-bottom: $split-item--inner-padding-v_mobile;
				}
			}
		}
	}
}

@include media( '>medium' ) {

	.split-wrap {
		margin-top: -($split-item--padding-v__desktop * 0.5);

		&:last-of-type {
			margin-bottom: -($split-item--padding-v__desktop * 0.5);
		}

	    &:not(:last-of-type) {
	        margin-bottom: $split-item--padding-v__desktop * 0.5;
	    }

		.split-item {
			padding-top: $split-item--padding-v__desktop * 0.5;
			padding-bottom: $split-item--padding-v__desktop * 0.5;
			flex-direction: row;
			flex-wrap: nowrap;
			align-items: center;
			justify-content: flex-start;

			.split-item-content {
				flex-shrink: 0;
				width: ($container--width-sm * 0.5) - $split-item-content--padding-h;
				margin-bottom: 0;
				margin-right: $split-item--inner-padding-h;
			}

			// .split-item-image {
			// 	min-width: ($container--width-sm * 0.5) - $split-item-image--padding-h;
			// }
		}

		&:not(.invert-desktop) .split-item:nth-child(even),
		&.invert-desktop .split-item:nth-child(odd) {
			justify-content: flex-end;

			.split-item-content {
				order: 1;
				margin-left: $split-item--inner-padding-h;
				margin-right: 0;
			}
		}

		&:not(.invert-desktop) .split-item:nth-child(odd),
		&.invert-desktop .split-item:nth-child(even) {

			.split-item-image {

				img,
				svg,
				video {
					margin-left: auto;
				}
			}
		}

		&.align-top {

			.split-item {
				align-items: flex-start;
			}
		}
	}
}

@include media( '>large' ) {

	.container {

		.split-wrap {

			.split-item {

				.split-item-content {
					width: ($container--width * 0.5) - $split-item-content--padding-h;
				}

				.split-item-image {
					min-width: ($container--width * 0.5) - $split-item-image--padding-h;
				}
			}
		}
	}
}