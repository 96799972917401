.container,
.container-sm,
.container-xs {
	width: 100%;
	margin: auto;
	padding-left: $container--padding__mobile;
	padding-right: $container--padding__mobile;

	@include media( '>small' ) {
		padding-left: $container--padding__desktop;
		padding-right: $container--padding__desktop;
	}
}

.container {
	max-width: $container--width + ( $container--padding__desktop * 2 );
}

.container-sm {
	max-width: $container--width-sm + ( $container--padding__desktop * 2 );
}

.container-xs {
	max-width: $container--width-xs + ( $container--padding__desktop * 2 );
}

.border-gradient {
        color: inherit;
        position: relative;
        display: inline-block;
		background-color: rgba(24,24,24, 0.85);
		border-radius: 2em;

		&::before {
			  content: "";
			  position: absolute;
			  inset: 0;
			  border-radius: 2em;
			  padding: 1px;
			  background: linear-gradient(119.83deg, #F3BA88 22.41%, #B95BD7 42.19%, #E360D4 56.73%, #384AE8 74.14%);
			  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
			  -webkit-mask-composite: xor; 
			  mask-composite: exclude;
		  }
}

.not-active-cta {
	// animation: slideOutInModal .15s ease-in-out both;
	display: none;
}

.active-cta {
	animation: slideUpInModal .20s ease-in-out both;
	display: auto;
}

@keyframes slideUpInModal {
    0% {
        opacity: 0;
        transform: translateY(-24px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


[class*=container] {

	[class*=container] {
		padding-left: 0;
		padding-right: 0;
	}

    .container-sm {
		max-width: $container--width-sm;
    }

	.container-xs {
		max-width: $container--width-xs;
	}
}
